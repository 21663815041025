<template>
  <v-container>
    <h2 class="display-2 font-weight-bold text-center mb-3">
      <p style="font-family: 'Humanist 521 Ultra Bold'">
        HISTORIA
      </p>
    </h2>

    <v-responsive
      class="mx-auto mb-6"
      width="56"
    >
      <v-divider class="mb-1"></v-divider>
      <v-divider></v-divider>
    </v-responsive>

    <v-timeline
      :dense="$vuetify.breakpoint.smAndDown"
    >
      <v-timeline-item
        v-for="(item, i) in items"
        :key="i"
        :color="item.color"
        small
      >
        <template v-slot:opposite>
          <span
            :class="`headline font-weight-bold ${item.color}--text`"
            v-text="item.year"
          ></span>
        </template>
        <v-card class="elevation-2">
          <v-card-title :class="`headline font-weight-light ${item.color}--text`" style="word-break: normal">
            <span
              v-if="$vuetify.breakpoint.smAndDown"
              :class="`headline font-weight-bold ${item.color}--text mr-2`"
              v-text="item.year"
            ></span>
            {{ item.title }}
          </v-card-title>
          <v-card-text
            v-html="item.text"
          ></v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        color: 'pink',
        year: '1990',
        title: 'Fundación',
        text: '<strong>Borigen Betzel</strong> nació en 1990 como "Consultora de Sistemas", el objetivo era producir software y hardware a medida de las empresas.',
      },
      {
        color: 'cyan',
        year: '1996',
        title: 'Agentes oficiales de Arnet',
        text: 'Se adquiere la franquicia de <strong>Arnet</strong>, primero en Salta y luego en Santiago del Estero.',
      },
      {
        color: 'orange',
        year: '2001',
        title: 'Se conforma GDB Comunicaciones',
        text: 'Se conforma GDB Comunicaciones, siendo <strong>Agentes Oficiales de Telecom Personal</strong> durante 20 años, llegando a tener presencia en 13 provincias.',
      },
      {
        color: 'red',
        year: '2009',
        title: 'Nace DYCAR',
        text: 'Nace DYCAR, incorporando al grupo en el rubro automotriz. <strong>Chevrolet</strong> nos nombra representantes oficiales en Salta.',
      },
      {
        color: 'blue',
        year: '2018',
        title: 'Surge CELUSHOP',
        text: 'Surge <strong>CELUSHOP</strong> con la comercialización de telefonía celular, accesorios y productos tecnológicos.',
      },
      {
        color: 'green',
        year: '2018',
        title: 'Se forma RADA',
        text: 'Se forma <strong>RADA</strong> (Rentadora de autos) en Salta.',
      },
      {
        color: 'purple',
        year: '2019',
        title: 'Nueva sucursal DYCAR',
        text: 'DYCAR inaugura una nueva sucursal en San Salvador de Jujuy.',
      },
      {
        color: 'indigo',
        year: '2021 - Actualidad',
        title: 'RADA en constante expansión',
        text: 'RADA se encuentra en expansión, inaugurando en 2021 sucursales en distintos puntos turísticos del país.',
      },
      {
        color: 'indigo',
        year: '',
        title: 'CELUSHOP retail',
        text: 'CELUSHOP retail presente en <strong>9</strong> provincias con <strong>45</strong> puntos de venta.',
      },
      {
        color: 'indigo',
        year: '',
        title: 'Se incorpora Citroën',
        text: 'Se incorpora la marca <strong>Citroën</strong> al grupo Betzel Betzel.',
      },
    ],
  }),
}
</script>