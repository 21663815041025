<template>
  <v-row no-gutters>
    <v-carousel
      :height="$vuetify.breakpoint.smAndDown ? '50vh' : '90vh'"
      cycle
      hide-delimiter-background
      delimiter-icon="fas fa-minus"
      show-arrows-on-hover
      :show-arrows="false"
      :hide-delimiters="true"
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        ><v-icon class="mr-1">fas fa-chevron-left</v-icon></v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        ><v-icon class="ml-1">fas fa-chevron-right</v-icon></v-btn>
      </template>

      <v-theme-provider style="position:absolute; z-index: 1; max-width: 100%" dark>
        <v-container fill-height>
          <v-row
            align="center"
            class="black--text"
            justify="center"
          >
            <v-col
              class="text-center"
              cols="12"
              tag="h1"
            >
              <span
                :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']"
                class="shadow-white"
              >
              <p style="font-family: 'Humanist 521 Ultra Bold'">
                Borigen Betzel
              </p>
              </span>
              <span
                :class="[$vuetify.breakpoint.smAndDown ? 'display-none border-red-thin' : 'display-2 border-red']"
                class="white--text"
              >
                <p style="font-family: 'Humanist 521 Ultra Bold'">
                  Un grupo en constante crecimiento
                </p>
              </span>
            </v-col>
            <v-btn
              class="align-self-end"
              color="white"
              fab
              @click="$vuetify.goTo('#about')"
            >
              <v-icon class="mt-1" color="grisbb" large>fas fa-angle-double-down</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-theme-provider>

      <v-carousel-item
        v-for="(item, index) in items"
        :key="index"
        :src="item.src"
        :style="item.opacity"
      >
      </v-carousel-item>
    </v-carousel>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      items: [
        { src: require('../assets/img/1.webp'), opacity: 'opacity: 0.90' },
        { src: require('../assets/img/2.webp'), opacity: 'opacity: 0.85' },
        { src: require('../assets/img/3.webp'), opacity: 'opacity: 0.90' },
        { src: require('../assets/img/5.webp'), opacity: 'opacity: 0.95' },
      ],
    }
  },
}
</script>

<style>
  .border-red {
    text-shadow: 2px 0 0 #CC0001, -2px 0 0 #CC0001, 0 2px 0 #CC0001, 0 -2px 0 #CC0001,
    1px 1px #CC0001, -1px -1px 0 #CC0001, 1px -1px 0 #CC0001, -1px 1px 0 #CC0001;
  }
  .border-red-thin {
    text-shadow: 1px 0 0 #CC0001, -1px 0 0 #CC0001, 0 1px 0 #CC0001, 0 -1px 0 #CC0001;
  }
  .shadow-white {
    text-shadow: -3px 3px 5px #ffff;
  }
  .shadow-dark {
    text-shadow: -1px 1px 3px #000000;
  }
</style>