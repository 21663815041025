<template>
  <v-container>
    <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">SERVICIOS</h2>

    <v-responsive
      class="mx-auto mb-10"
      width="56"
    >
      <v-divider class="mb-1"></v-divider>

      <v-divider></v-divider>
    </v-responsive>

    <v-row class="d-flex justify-center">
      <v-col
        v-for="({ src, text, title }, index) in items"
        :key="index"
        class="mx-6"
        cols="12"
        md="5"
      >
        <v-img
          :src="src"
          class="mb-4"
          height="275"
          max-width="100%"
        ></v-img>

        <h3
          class="font-weight-black mb-4 text-uppercase"
          v-text="title"
        ></h3>

        <div
          class="title font-weight-light mb-5"
          v-text="text"
        ></div>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        src: require('../../src/assets/call.webp'),
        title: 'SERVICIOS PARA PERSONAS',
        text: 'Todo lo que necesitas para estar siempre comunicado, pasa por uno de nuestros locales y lleva toda la magia de la comunicación en un plan a tu medida, con todos los beneficios que solo BorigenBetzel te pueden ofrecer. Además el equipo que buscas, nosotros lo tenemos',
      },
      {
        src: require('../../src/assets/call2.webp'),
        title: 'SOLUCIONES PARA EMPRESAS',
        text: 'Las respuestas que necesita para optimizar la comunicación de su empresa: planes diseñados en función a sus necesidades, con la finalidad de facilitar las comunicaciones de su empresa y lograr el máximo rendimiento de su presupuesto para tal fin. No dude en solicitar el asesoramiento de nuestro equipo de ventas profesional.',
      },
    ],
  }),
}
</script>