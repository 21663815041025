<template>
  <v-container class="text-center">
    <h2 class="display-2 font-weight-bold mb-3">
      <p style="font-family: 'Humanist 521 Ultra Bold'">
        EMPRESAS
      </p>
    </h2>

    <v-responsive
      class="mx-auto mb-10"
      width="56"
    >
      <v-divider class="mb-1"></v-divider>

      <v-divider></v-divider>
    </v-responsive>

    <v-row class="d-flex justify-center">
      <v-col
        v-for="({ icon, title, text, link }, index) in features"
        :key="index"
        cols="12"
        md="4"
      >
        <v-hover
           v-slot="{ hover }"
        >
          <v-card
            :elevation="hover ? 12 : 2"
            class="py-12 px-4"
            color="grey lighten-5"
            flat
            height="450"
            :href="link"
            target="_blank"
          >
            <v-theme-provider dark>
              <div>
                <v-avatar
                  color="grisbb"
                  style="border: 1px solid"
                  :size="hover ? 108 : 88"
                >
                  <v-img :src="icon"></v-img>
                </v-avatar>
              </div>
            </v-theme-provider>

            <v-card-title
              class="justify-center font-weight-black text-uppercase rojobb--text"
              v-text="title"
            ></v-card-title>

            <v-card-text
              class="subtitle-1 grisbb--text"
              v-text="text"
            ></v-card-text>

            <v-card-actions class="d-flex justify-end">
              <v-btn
                color="rojobb"
                text
                dark
              >Ver más</v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    features: [
      {
        icon: require('../../src/assets/logos/rfb.webp'),
        title: 'CeluShop',
        text: 'Venta de productos de tecnología (celulares, notebook, smart tv, smart watch) y accesorios con una red comercial de 45 sucursales en 9 provincias.',
        link: 'https://celushop.com.ar',
      },
      {
        icon: require('../../src/assets/logos/chevrolet.webp'),
        title: 'CHEVROLET-DYCAR',
        text: 'Concesionario oficial de Chevrolet en las provincias de Salta y Jujuy con servicios de venta, posventa y comercialización de planes de ahorros.',
        link: 'https://www.chevroletdycar.com.ar',
      },
      {
        icon: require('../../src/assets/logos/citroen.webp'),
        title: 'CITROËN-DYCAR',
        text: 'Concesionario oficial de Citroën en la provincia de Salta con servicios de venta, posventa y comercialización de planes de ahorros.',
        link: 'https://citroen.dycar.com.ar',
      },
      {
        icon: require('../../src/assets/logos/usados.webp'),
        title: 'USADOS-DYCAR',
        text: 'Comercialización de vehículos usados en Salta y Jujuy.',
        link: 'https://usados.dycar.com.ar',
      },
      {
        icon: require('../../src/assets/logos/rada.webp'),
        title: 'RADA',
        text: 'Alquiler de vehículos con distintas sucursales en Salta y Jujuy.',
        link: 'https://www.radarentacar.com.ar',
      },
      /*{
        icon: require('../../src/assets/logos/4067.webp'),
        title: '4067 Automotores',
        text: 'Nueva concesionaria en la provincia de Corrientes.',
        link: 'https://www.4067autos.com.ar',
      },*/
    ],
  }),
}
</script>