<template>
  <v-main style="padding: 0px">
    <section id="home">
      <Inicio />
    </section>

    <section id="about" class="py-12">
      <Nosotros />
    </section>

    <section
      id="timeline"
      class="grey lighten-3 py-12"
    >
      <Timeline />
    </section>

    <v-parallax
      :height="$vuetify.breakpoint.smAndDown ? 600 : 400"
      src="../assets/banner.webp"
    >
      <v-container fill-height>
        <v-row class="mx-auto">
          <v-col cols="12">
            <div class="text-center">
              <div :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'">
                <p style="font-family: 'Humanist 521 Ultra Bold'">
                  Borigen Betzel
                </p>
              </div>

              <div
                class="font-weight-regular"
                :class="$vuetify.breakpoint.smAndDown ? 'title' : 'display-1'"
              >
                <p style="font-family: 'Humanist 521 Ultra Bold'">
                  Un grupo que crece… siempre
                </p>
              </div>
            </div>
          </v-col>
          <v-col
            v-for="[value, title] of numbers"
            :key="title"
            cols="12"
            md="4"
          >
            <div class="text-center">
              <div
                class="display-3 font-weight-black mb-4"
                v-text="value"
              ></div>

              <div
                class="title font-weight-regular text-uppercase"
                v-text="title"
              ></div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>

    <section
      id="company"
      class="grey lighten-3 py-12"
    >
      <Empresas />
    </section>

    <v-slide-y-reverse-transition>
      <v-btn
        v-show="scrollPosition > 500 ? true : false"
        color="rojobb"
        elevation="12"
        bottom
        right
        fixed
        dark
        fab
        @click="$vuetify.goTo(0)"
      ><v-icon>fas fa-arrow-up</v-icon></v-btn>
    </v-slide-y-reverse-transition>
  </v-main>
</template>

<script>
import Inicio from '../components/Inicio'
import Nosotros from '../components/Nosotros'
import Empresas from '../components/Empresas'
import Servicios from '../components/Servicios'
import Contacto from '../components/Contacto'
import Timeline from '../components/Timeline'

export default {
  name: 'Home',

  data: () => ({
    scrollPosition: null,
    numbers: [
      ['9', 'Provincias'],
      ['50', 'Sucursales'],
      ['328', 'Colaboradores'],
    ],
  }),
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  components: {
    Inicio, Nosotros, Empresas, Servicios, Contacto, Timeline
  },
   methods: {
    updateScroll() {
       this.scrollPosition = window.scrollY
    },
  }
}
</script>

<style scoped>
  .v-content {
    padding: 0px;
  }
</style>